.about-main {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    background: white;
}


.services-offered {
    display: flex;
    flex-direction: row;
}

.about-banner {
    margin: 100px 0 0 0;
    height: calc(50vh - 100px);
    background-image: url(/backgrounds/jersey_city_grayscale.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.who-we-are {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 15% 75px 15%;
}

.who-we-are p{
    font-size: 1.5em;
    font-family: Copperplate;
    font-weight: 300;
    text-align: center;
}



.service {
    flex-basis: 30%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 40px 15px 60px 15px;
    justify-items: center;
    background: #F0F0F0;
    text-align: center;
}

.service:nth-child(2) {
    background: white;
}

.service-title {
  height: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 1em;
}

.service h1 {
    align-self: flex-end;
    font-family: Copperplate;
    color: #ff4c4c;
}

.service:nth-child(2) h1 {
    color: #ff4c4c;
}


.service p {
    align-self: flex-end;
    font-size: 1.4em;
    font-family: Monserrat;
}


@media(max-width: 1500px) {
    .services-offered {
        flex-wrap: wrap;
    }

    .service {
        flex-basis: 100%;
        text-align: left;
        padding: 50px 150px 60px 50px;
        border-top: 1px solid gainsboro;
        border-bottom: 1px solid gainsboro;
    }

    .service h1 {
        align-self: flex-end;
        font-size: 2.8em;
        font-family: Copperplate;
    }

    .service-title {
        justify-content: flex-start;
        height: 50px;
    }
}

@media(min-width: 1501px) {
    .service {
        border: 1px solid gainsboro;
    }

}



