
.application-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    margin: 0 0 0 0;
    justify-content: space-between;
    padding: 50px 50px;
}


.application-div {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    flex: 1 1 300px;
    align-items: center;
}

.application-div h1 {
    font-size: 2em;
    margin: 0 0 0 0;
    text-align: center;
}

.application-div p {
    font-size: 1em;
    margin: 0 0 2px 0;
}

.address {
    font-size: 1em !important;
    font-weight: 500;
    color: gray;
}

.form {
    width: 100%;
}

.application-form-container {
    flex: 2 2 500px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start;
}


.application-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-self: flex-start;
    margin: 0 0 25px 0;
}

.application-text h1{
    font-size: 2em;
    margin: 0 0 0 0;
    color: #ff4c4c;
}


.form-response {
    color: red;
}

@media(max-width: 925px) {
    .application-div {
        padding-left: 0;
    }
}


/*
.application {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    justify-content: center;
    align-items: center;  
    height: 90vh;
}
*/



