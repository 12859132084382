.residents-banner {
    height: 60vh;
    background-image: url(/backgrounds/jersey_city.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.residents-banner-text {
    margin: 100px 0 0 0;
    height: calc(60vh - 100px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.residents-banner-text h1 {
    font-size: 6em;
    text-transform: uppercase;
    color: white;
    opacity: 1;
    font-weight: 700;
    text-shadow: 2px 5px black;
    margin: 0 10px 0px 10px;
    text-align: center;
}

/*dimgrey*/

.residents-banner-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.residents-banner-buttons div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    flex-basis: 25%;
    text-align: center;
    max-width: 400px;
}


.residents-banner-text-button {
    width: 250px;
    padding: 30px 10px;
    background-color: #ff4c4c;
    color: white;
    font-size: 1.5em;
    text-align: center;
    text-shadow: none;
    font-weight: 600;
    border-radius: 5px;
    transition: transform .2s; /* Animation */
    margin: 7px 20px;
}




.residents-banner-text-button:hover {
    transform: scale(1.04); 
    
}

.residents-banner-text-button:active {
    color: wheat;
    background-color: #2f2f2f !important;
}


.residents-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 20px;
    font-size: 1.1em;
    align-items: stretch;
    background: #F8F8F8;
}

.residents-info > div:nth-child(odd) {
    border: 1px solid gainsboro;
}

.residents-info > div:nth-child(even) {
    border: 1px solid gainsboro;
    border-left: none;
}


#resident-info-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.3em;
    flex-basis: 50% !important;
    flex-wrap: wrap;
    background:#ff4c4c;
    color: black;
    font-weight: 500;
    padding: 30px 50px 40px 50px;
    min-height: 400px;
    font-family: Monserrat;
}

#resident-info-text h1 {
    font-size: 2.3em;
    margin: 0 0 20px 0;
    color: white;
    font-family: Copperplate;
    text-align: center;
}


#resident-info-img {
    display: flex;
    align-items: stretch;
    background-image: url(/backgrounds/jersey_city.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    flex-basis: 50% !important;
}

.resident-info-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.resident-info-buttons a {
    text-align: center;
    margin: 10px 5px;
    padding: 13px 20px;
    background: whitesmoke;
    border-radius: 5px;
    color: #ff4c4c;
    font-family: Copperplate;
    transition: transform .2s; /* Animation */
}

.resident-info-buttons a:hover {
    transform: scale(1.04); 
}

.resident-info-buttons a:active {
    background: #2f2f2f;
    color: wheat; 
}


@media (max-width: 830px) {
    #resident-info-text {
        flex-basis: 95% !important;
    }
    #resident-info-text:nth-child(1){
        margin-bottom: 15px;
    }
    #resident-info-img {
        display: none;
    }
}

@media (max-width: 650px) {
    .residents-banner-text {
        font-size: 0.8em;
    }
}

@media (max-width: 500px) {
    .residents-banner-text {
        font-size: 0.7em;
    }
}

@media (max-width: 400px) {
    .residents-banner-text {
        font-size: 0.6em;
    }
}