.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
}


@media (min-width: 1101px) {
    .backdrop {
        display: none;
    }
}