
.toolbar {
    position: absolute;
    z-index: 10;
    width: 100% !important;
    top: 0;
    left: 0;
    background-color: whitesmoke; /* rgba(175, 222, 103, 1);*/
    height: 100px;
    border-bottom: 1px solid darkgray;
}

.transparent {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(240,240,240, 0.8); /* lighter: 208, 194, 191     darker: 161, 133, 128*/
  height: 100px;
}



.toolbar_nav {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 2rem 0 1rem;
}


.toolbar_logo a {
  margin-left: 1rem;
}


.toolbar_nav-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-family: Montserrat;
    text-decoration: none;


}

.toolbar_nav-items li {
    display: inline-block;
    padding: 0px 0 0px;
    text-decoration: none;

  }
  
  .navLink {
    text-decoration: none;
    position: relative;
    display: block;
    padding: 3px 0;
    margin: 0 12px;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: color 0.1s, background-color 0.1s;
    color: #003B46;
  }
  .toolbar_nav-items a:hover {
    color: #003B46;
    text-decoration: none;

  }
  .toolbar_nav-items a:focus, a:active {
    color: #003B46;
    text-decoration: none;

  }
  
  .toolbar_nav-items a::before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    height: 3px;
    width: 100%;
    background-color: #003B46;
    -webkit-transform-origin: center top;
            transform-origin: center top;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    transition: color 0.1s, -webkit-transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  }
  
  .toolbar_nav-items a:active::before {
    background-color: #003B46;
  }
  
  .toolbar_nav-items a:hover::before,
  .toolbar_nav-items a:focus::before {
    -webkit-transform-origin: center top;
            transform-origin: center top;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }


.spacer {
    flex: 1;
}



li.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {background-color: #f1f1f1;color:black;}

/* .dropdown-content a:hover {background: rgba(75,169,200, 0.5);color:black;} */

.dropdown-content a::before {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}






@media (max-width: 950px) {
    .toolbar_nav-items {
        display: none;
    }
}

@media (min-width: 951px) {
    .toolbar_toggle-button {
        display: none;
    }
    .toolbar_logo {
        margin-left: 0;
    }
}


