.home {
    display: flex;
    flex-direction: column;
    background: #F5F5F5; /* #ff4c4c */
}


.home-banner {
    height: 100vh;
    background-image: url(/backgrounds/woman_sitting.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.home-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 12%;
    font-size: 1.4em;
    font-family: Copperplate;
    font-weight: 300;
    text-align: center;
    color: black;
}

.available-rentals {
    padding: 0 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
}


.neighborhoods {
    padding: 0 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
}

.neighborhood-slide {
    height: calc(100vw / 5);
    object-fit: cover;
}

.neighborhood-slide img {
    height: 100%;
    width: 100%;
}

.bottom-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 75%;
    background: rgba(250, 250, 250, 0.7);
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20%;
    text-align: center;
    font-size: 1.8em;
    font-family: Copperplate;
}

.bottom-left:hover {
    background: rgba(250, 250, 250, 0.9);
}


.home-banner-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.home-banner-text p {
    font-size: 4.7em;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    text-shadow: 2px 4px black;
    margin: 0 0 5px 30px;
}

.home-banner-text p:nth-child(1) {
    margin-top: 150px;
}

/*dimgrey*/

.home-banner-text-button {
    margin: 15px 0 15px 30px;
    width: 250px;
    padding: 15px 5px;
    background-color: #ff4c4c;
    color: white;
    font-size: 1.5em;
    text-align: center;
    text-shadow: none;
    font-weight: 600;
    border-radius: 5px;
    transition: transform .2s; /* Animation */
    font-family: Copperplate;
}

.home-banner-text-button:hover {
    transform: scale(1.04); 
}

.home-banner-text-button:active {
    color: wheat;
    background-color: #2f2f2f !important;
}

@media(max-width: 700px){
    .bottom-left {
        height: 40% !important;
        font-size: 1.5em;
    }
}

@media(max-width: 600px) {
    .home-intro {
        padding: 50px 5%;
    }
    .home-banner-text p {
        font-size: 3.5em;
    }
}

@media(max-width: 400px) {
    .home-banner-text p {
        font-size: 3em;
    }
}

