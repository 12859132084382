.footer {
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.footer-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
}

.contact-info {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    justify-content: center;
}

.contact-info p {
    margin: 0.2em;
}

.sitemap{
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    justify-content: flex-start;
    margin: 0;
}

.sitemap p{
    margin: 0.2em;
}


