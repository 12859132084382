
.title-container{
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 2px solid black;
}




.title {
    text-align: center;
    font-family: Copperplate; 
    font-size: 4em; 
}

/* text-shadow: -1px -1px 0 black, 2px -1px 0 black, -1px 1px 0 black, 2px 2px 0 black;
 */


@media (max-width: 650px){
    .title{
        font-size: 2.6em !important;
    }
}