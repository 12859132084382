

.search-results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: whitesmoke;

}

.apartments-list {
    height: 500px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: flex-start;
    padding: 0px 0px 0px 0px;
}

.apartment-card {
    display: flex;
    flex-direction: row;
    max-height: 200px !important;
    /*margin: 0 1% 0 2%;
    padding: 2% 0 2% 0;*/
    border-bottom: 1px solid lightgray;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    background: white;
    transition: transform .2s; /* Animation */
}

.apartment-card:hover, .apartment-card.selected {
    /*transform: scale(1.0065);*/
    background-color: rgb(236, 245, 236);
}

.apartment-img {
    display: flex;
    flex-direction: column;
    flex-basis: 70%;
    height: 200px;
    justify-content: center;
    align-items: center;
    margin: 0 25px 0 0;
}

.apartment-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.apartment-text {
    display: flex;
    flex-direction: column;
    flex-basis: 80%;
    height: 100%;
    padding: 2% 1% 0 0;
    justify-content: flex-start;
    color: black;
}

.apartment-text>p:nth-child(1) {
    font-size: 1.6em;
    font-weight: 500;
    font-family: Copperplate;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.apartment-text>p:nth-child(2) {
    font-size: 1em;
    color: gray;
    margin: 0 0 15px 0;
}

.card-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 1em !important;
}

.card-description p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 1em !important;
}




.apartments-map {
    position: relative;
    width: 100%;
    height: 100%;
    flex-basis: 50%;
}

#map {
    width: 50vw;
    height: 500px;
}


.apartments-grid::after {
    content: "";
    flex: auto;
}

.map-marker {
    transform: translate(calc(25/2)px,calc(25/2)px);
    cursor: pointer;
    fill: #d00;
    stroke: none;
}



.apartment {
    flex-basis: 33%;
    padding: 10px 10px;
    flex-shrink: initial;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    margin: 0 0 15px 0;
  }
  
  
  .apartment-title {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 10px;
  }
  
.apartment-title h2 {
    font-family: Copperplate; font-weight: 300;;
    text-align: center;
    margin: 0;
    font-weight: 300;
  }

  .apartment-title h3 {
    font-family: Copperplate; font-weight: 300;;
    text-align: center;
    margin: 0;
    font-size: 1em;
    color: #696969;
  }
  
  
.apartment-search {
    display: flex;
    flex-direction: column;
    background: #ff4c4c;
    justify-content: center;
    padding: 20px 25px 20px 25px;
}

.apartment-search h1 {
   color: white;
   font-size: 2.8em;
   margin: 0;
   text-align: center;
   font-family: Copperplate;
}


.search-boxes {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 5000;
}

.search-boxes {
    flex-basis: 100%;
}

.results-spinner {
    height: 500px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: center;
    align-items: center;
    background: #323232;
}


.no-results {
    height: 500px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 50px 0px;
    background: whitesmoke;
}

.no-results p{
    padding: 50px 0;
    text-align: center;
    font-size: 2em;
    color: darkgray;
}

#mapid { height: 180px; }

.marker-button {
    background: none;
    border: none;
    cursor: pointer;
}

.marker-link {
    text-align: center;
    width: 100%;
}


.leaflet-container {
    position: relative;
    width: 100%;
    height: 500px;
    flex-basis: 50%;
}


@media(max-width: 700px) {
    
}

@media(max-width: 900px) {
    .apartments-list {
        flex-basis: 100%;
    }

    .apartment-card {
        max-height: 400px !important;
    }

    .leaflet-container {
        display: none;
        flex-basis: 100%;
    }

    .no-results {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        height: 200px;
        flex-wrap: wrap;
        justify-self: center;
        justify-content: center;
        padding: 50px 10px;
    }
    
    .no-results p{
        padding: 0 0;
        text-align: center;
        font-size: 1.6em;
    }
    
}

@media(max-width: 700px){
    .apartment-search{ 
        padding: 20px 10px 20px 10px;
    }
    .search-boxes{
        
    }

}