
.contact-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    margin: 100px 0 0 0;
    justify-content: space-between;
    padding: 75px 50px;
}


.contact-div {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    flex: 1 1 300px;
    align-items: center;
}

.contact-div h1 {
    font-size: 2em;
    margin: 0 0 0 0;
    text-align: center;
}

.contact-div p {
    font-size: 1em;
    margin: 0 0 2px 0;
}

.address {
    font-size: 1em !important;
    font-weight: 500;
    color: gray;
}



.form-container {
    flex: 2 2 500px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: left;
}


.contact-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 10px 0;
}

.contact-text h1{
    font-size: 2em;
    margin: 0 0 0 0;
}


.form-response {
    color: red;
}

@media(max-width: 925px) {
    .contact-div {
        padding-left: 0;
    }
}


/*
.contact {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    justify-content: center;
    align-items: center;  
    height: 90vh;
}
*/



