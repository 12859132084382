
.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
    transform: translateX(0)
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    padding: 15px 0px 15px 0px;
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    margin: 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    list-style: none;
    flex-direction: column;
    justify-content: start;
}

.side-drawer li {
    display: inline-block;
    padding: 14px 0 0px;
    text-align: center;
  }
  
  .side-drawer a {
    color: black;
    text-decoration: none;
    position: relative;
    display: block;
    padding: 3px 0;
    margin: 0 12px;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    transition: color 0.1s, background-color 0.1s;
  }
  .side-drawer a:hover {
    color: #0275d8;
  }
  .side-drawer a:focus, a:active {
    color: #0275d8;
  }
  

@media (min-width: 1101px) {
    .side-drawer {
        display: none;
    }
}