.values-section {
    padding: 20px 20px 100px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.values-accordion {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}



.accordion-icon-inactive {
    justify-self: center;
    text-align: center;
    color:white;
    text-decoration: none;
    outline:0;
}

.accordion-icon-active {
    justify-self: center;
    text-align: center;
    color:#D5CB8E;
    text-decoration: none;
    outline:0;
}


.accordion-item-title-inactive {
    color: #181818;
    font-size: 2em;
    font-family: Copperplate;
    font-weight: 500;
}


.accordion-item-title-active {
    color: whitesmoke !important;
    font-size: 2em;
    font-family: Copperplate !important;
    font-weight: 500 !important;
}



.accordion-item {
    flex-basis: 30%;
    padding: 50px 50px;
    margin: 5px 5px;
    background: #ff4c4c;
    transition: transform .2s; 
}



.accordion-item:hover {
    transform: scale(1.007);
    box-shadow: inset 0 0 3px #181818; 
    cursor: pointer;
}


.accordion-item p {
    margin: 20px 0 0 0;
    font-size: 1.5em;
    text-align: center;
    color: black;
    font-family: Monserrat;
    text-align: left;
}

@media(max-width: 1000px) {
    .accordion-item {
        flex-basis: 40%;
    }
}

@media(max-width: 800px) {
    .accordion-item {
        flex-basis: 100%;
    }
    .accordion-item p {
        text-align: center;
    }
}


