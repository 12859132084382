#unit-list {
    height: 500px;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: flex-start;
    padding: 0px 0px 0px 0px;
}


#unit-rent {
    font-size: 1.3em;
    color: green;
    margin: 0;
    margin-top: 0px;
}

#unit-date-available {
    font-size: 1.3em;
    color: orange;
    margin-bottom: 20px;
}

#unit-card {

}

#unit-card:hover, #unit-card.selected {
    /*transform: scale(1.0065);*/
    background-color:  #FFEDE8;
}

#unit-card-img {
    display: flex;
    flex-direction: column;
    flex-basis: 70%;
    height: 200px;
    justify-content: center;
    align-items: center;
    margin: 0 25px 0 0;
}

#available-units-buttons {
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    margin-bottom: 10px;
}

#available-units-buttons a:nth-child(1) {
    margin: 0 5px 0 0;
}

#available-units-buttons a:nth-child(2) {
    margin: 0 0 0 5px;
}

#available-units-buttons a {
    font-size: 1.3em;
    margin: 10px 0 0 0;
    padding: 10px 15px;
    background: whitesmoke;
    border-radius: 5px;
    color: #ff4c4c;
    font-family: Copperplate;
    transition: transform .2s; /* Animation */
    cursor: pointer;
}



@media (max-width: 600px) {
    #unit-card-img {
        flex-basis: 50%;
    }
}

@media (max-width: 400px) {
    #unit-card-img {
        flex-basis: 0%;
    }
}