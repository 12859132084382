

#lease-terms {
    margin: 15px 0 15px 0;
}

#lease-terms p{
    margin-bottom: 0;
    margin-top: 0;
}

#lease-terms p:nth-child(1){
    margin: 30px 0 5px 10px;
    font-weight: 600;
}



.property-container {
    width: 100%;
    margin: 100px 0 0 0;
    background: #F0F0F0;
}


.property-banner-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 30px;
    cursor: pointer;

}

.property-banner-overlay h1 {
    font-size: 6.5em;
    font-weight: 600;
    color: #ff4c4c;
    font-family: Copperplate;
    text-shadow: 2px 2px black;
    margin: 0 0 0 0;
}

.property-banner-overlay h3 {
    font-size: 2.8em;
    font-weight: 400;
    color: whitesmoke;
    font-family: Copperplate;
    font-weight: 300;
    text-shadow: 1px 1px black;
    border-bottom: 1px solid gainsboro;
    padding: 0 30px;
    margin: 0 0 50px 0;
}

.property-banner-overlay p {
    font-size: 2.3em;
    color: white;
    font-family: Copperplate;
    font-weight: 300;
}

.property-banner:hover .property-banner-overlay {
    display: flex;
}

.property-info-wrapper {
    display: flex;
    flex-direction: row;
    margin: 50px 0 50px 0;
    justify-content: flex-start;
    align-items: flex-start;
}


.property-box {
    font-size: 1.1em;
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 3% 0px 3%;
    margin: 0 0 0 0;
}


.property-features {
    margin: 40px 0 0 0;
}

.property-features p {
    margin: 0 0 5px 20px;
    font-size: 1.4em !important;
    font-family: Copperplate;
    font-weight: 300;
}

.property-features ul {
    padding: 0 0 0 20px;
}

.property-features li {
    font-size: 1.1em;
    margin: 0 0 5px 0;
}



.property-box-lists {
    margin: 10px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.property-box-lists:nth-child(2) {
    margin: 25px 0 25px 0 !important;
}

.property-box-list {
    flex-basis: 50%;
    list-style: square;
}

.property-box-list>ul{
    flex-basis: 50%;
    list-style: square;
}

.property-box-list>ul{ 
    padding: 0;
}

.property-box-list:nth-child(1){ 
    padding-left: 20px;
    padding-right: 20px;
}

.property-box-list:nth-child(2){ 
    padding-left: 40px;
    padding-right: 5px;
}

.property-box-list p{
    text-align: left;
    font-size: 1.6em !important;
    margin: 0 0 5px -25px;
    font-family: Copperplate;
    font-weight: 500;
}

.property-box-list li{
    font-size: 1.13em !important;
    margin: 15px 0;
    max-width: 400px;

}


.property-carousel {
    width: 100%;
    background: slategray;
}

.property-box p {
    font-size: 1.2em;
}

.property-box-text {
    flex-basis: 60%;
    flex: 1;
    margin: 0 0 0 0;
    font-size: 1.1em;
    background: seashell;
    padding: 10px 10px 50px 10px;
}

.current-availabilities {
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#rental-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 20px 50px 20px;
}


#no-availabilities {
    height: 150px;
    width: 100%;
    font-size: 1.3em;
    margin: 0 0 50px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}








.rentals-accordion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
}




.accordion-item-inactive {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    flex-wrap: wrap;
    outline:0;
    text-align: center;
    cursor: pointer;
}

.accordion-item-inactive p {
    font-family: Copperplate;
    text-decoration: none;
    outline: 0;
    margin: 0;
    flex-basis: 30%;
}

.accordion-item-inactive p:nth-child(1) {
    font-size: 2.5em;
    color:white;
    text-shadow: 1px 1px black;

}

.accordion-item-inactive p:nth-child(2) {
    font-size: 1.4em;
    color: cornsilk;
}

.accordion-item-inactive p:nth-child(3) {
    font-size: 1.4em;
    color: cornsilk;
}

.accordion-item-inactive p:nth-child(4) {
    padding: 5px 0;
    font-size: 1.5em;
    color: #aed75b;
    font-weight: bold;
}


#rental-icon {
    flex-basis: 100%;
}


.accordion-item-active {
    color:#D5CB8E;
    text-decoration: none;
    outline: 0;
    margin: 0;
    cursor: pointer;
}

.accordion-item-active p {
    color:#D5CB8E;
    text-decoration: none;
    outline: 0;
    margin: 0;
    color: #181818;
    font-family: Copperplate;
    font-weight: 300;
    margin: 0;
    text-align: center;
}

.accordion-item-active p:nth-child(1) {
    font-size: 3.8em;
    color:#D5CB8E;
    font-family: Copperplate;
    text-shadow: 1px 2px black;
}
.accordion-item-active p:nth-child(2) {
    font-size: 1.9em;
    color:white;
}
.accordion-item-active p:nth-child(3) {
    font-size: 1.9em;
    color:white;
}

.accordion-item-active p:nth-child(4) {
    padding: 5px 0;
    font-size: 1.5em;
    color: #aed75b;
    font-weight: bold;
}




.accordion-item-title-inactive {
    
}


.accordion-item-title-active {
    color: whitesmoke;
    font-size: 2em;
    font-family: Copperplate;
    font-weight: 300;
}



.rentals-accordion-item {
    width: 100%;
    flex-basis: 100%;
    padding: 20px 25px 10px 25px;
    margin: 5px 5px;
    background: #ff4c4c;
    transition: transform .2s;
    box-shadow: inset 0 0 3px #181818; 
 
}


.rentals-accordion-item:hover {
    transform: scale(1.007);
}



.rental-accordion-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0 0 0;
    justify-content: center;
}

.rental-accordion-panel ul {
    padding: 0 0 0 30px;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap;
    max-height: 175px; */
    list-style: square;
}


#rental-panel-features {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    font-size: 1.2em;
    align-items: center;
}

#rental-panel-features h3 {
    font-family: Copperplate;
    margin-bottom: 15px;
    border-bottom: 1px solid gainsboro;
    font-size: 1.7em;

}

#rental-panel-lease-terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 50%;
    font-size: 1.2em;
   
}

#rental-panel-lease-terms h3 {
    font-family: Copperplate;
    margin-bottom: 15px;
    border-bottom: 1px solid gainsboro;
    font-size: 1.7em;
}



.rental-panel-buttons {
    padding: 25px 0;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: center;
    text-align: center;

}

.rental-panel-buttons a:nth-child(1) {
    margin: 0 15px 0 0;
}

.rental-panel-buttons a:nth-child(2) {
    margin: 0 0 0 15px;
}

.rental-panel-buttons a {
    font-size: 1.4em;
    margin: 10px 0 0 0;
    padding: 13px 20px;
    background: whitesmoke;
    border-radius: 5px;
    color: #ff4c4c;
    font-family: Copperplate;
    transition: transform .2s; /* Animation */
    cursor: pointer;
}

.rental-panel-buttons a:hover {
    transform: scale(1.04); 
}

.rental-panel-buttons a:active {
    background: #2f2f2f;
    color: wheat; 
}

.property-spinner { 
    height: calc(100vh - 100px);
    margin-top: 100px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-content: center;
    align-items: center;
    background: #323232;
}




@media (max-width: 1000px) {
    .property-carousel {
        flex-basis: 85%;
    }
    .property-box-text {
        flex-basis: 100%;
    }
}

@media (max-width: 1500px) {
    .property-info-wrapper {
        flex-wrap: wrap;
    }

    .property-box {
        flex-basis: 100%;
        padding: 15px 10% 0px 10%;
    }

    .current-availabilities {
        margin: 75px 0 0 0;
        flex-basis: 100%;
    }

    .property-info-wrapper {
        margin-bottom: 0;
    }
    

}


@media (max-width: 1000px) {
    
    .property-box-lists {
        flex-wrap: wrap;
    }
    .property-box-list {
        flex-basis: 100%;
    }

    .property-box-list {
        padding-left: 20px;
    }

    .property-box-list:nth-child(2) {
        margin: 30px 0 0 0;
        padding-left: 20px;
    }

    .property-box-list p{
        text-align: left;
    } 
    .property-banner-overlay {
        font-size: 0.8em;
    }
  }

#property-box-text {
    margin-bottom: 50px;
}

  

@media (max-width: 550px) {
    .property-carousel {
        flex-basis: 100%;
    }
    .property-banner-overlay {
        font-size: 0.6em;
    }
  }
  

  @media (max-width: 375px) {
    .property-banner-overlay {
        font-size: 0.5em;
    }
  }
  
