.apartments-container {
    margin: 100px 0 0 0;
}

.apartments-banner {
    height: 66vh;
    background-image: url(/backgrounds/jersey_city.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.apartments-banner-text {
    height: calc(66vh - 100px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
}

.apartments-banner-text h1 {
    font-size: 5.2em;
    text-transform: uppercase;
    color: white;
    opacity: 1;
    font-weight: 700;
    text-shadow: 2px 5px black;
    margin: 0 0 0px 0;
    text-align: center;
}



.current-properties-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.apartments-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: center;
    margin: 0 25px;
    padding: 0px 0 25px 0px;
}

.apartments-grid::after {
    content: "";
    flex: auto;
  }



.apartment-grid-item {
    flex-basis: 50%;
    padding: 10px 10px;
    flex-shrink: initial;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    margin: 0 0 15px 0;
    cursor: pointer !;
  }
  
  
  .apartment-grid-item-title {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 10px;
  }
  
  .apartment-grid-item-title h2 {
    font-family: Copperplate; font-weight: 300;;
    text-align: center;
    margin: 0;
    font-weight: 300;
  }

  .apartment-grid-item-title h3 {
    font-family: Copperplate; font-weight: 300;;
    text-align: center;
    margin: 0;
    font-size: 1em;
    color: #696969;
  }
  
  
  
  .apartments-grid img {
      border: 1px solid #ddd; /* Gray border */
      border-radius: 4px;  /* Rounded border */
      padding: 5px; /* Some padding */
      width:100%;
      object-fit:cover;
  }
  
  .apartments-grid img:hover {
      box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
  


    @media (max-width: 400px){
        .apartments-grid div {
          flex-basis: 100%;
        }
      }

    
    @media (max-width: 600px){
        .apartment-intro p, .apartment-intro li {
            font-size: 0.9em !important;
        }
        .apartments-banner-text h1 {
            font-size: 3.5em;
        }
    }
    
    
      @media (min-width: 1200px){
        .apartments-grid div {
          flex-basis: 33%;
        }
      }
    
      @media (min-width: 1500px){
        .apartments-grid div {
          flex-basis: 25%;
        }
      }
    
      @media (min-width: 2000px){
        .apartments-grid div {
    
        }
      }
    