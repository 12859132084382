
.careers-container {
    width: 100%;
    margin: 100px 0 0 0;
    background: #F0F0F0;
}

.careers-info-wrapper {
    display: flex;
    flex-direction: column;
}

.careers-intro {
    display: flex;
    flex-direction: column;
   
    padding: 60px 10% 60px 50px;
    text-align: left;
    background-color: #F5F5F5;
}

.careers-intro h1 {
    font-size: 2.2em;
}
.careers-intro p {
    font-size: 1.4em;
    font-family: Copperplate;
    font-weight: 300;
}

#open-positions-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    flex-basis: 25%;
    text-align: center;
    max-width: 400px;
    background-color: teal;
}

#open-positions-button {
    width: 250px;
    padding: 15px 10px;
    background-color: #ff4c4c;
    color: white;
    font-size: 1.1em;
    text-align: center;
    text-shadow: none;
    font-weight: 600;
    border-radius: 5px;
    transition: transform .2s; /* Animation */
    margin: 7px 0;
}




#open-positions-button:hover {
    transform: scale(1.04); 
    
}

#open-positions-button:active {
    color: wheat;
    background-color: #2f2f2f !important;
}



.careers-banner-overlay {
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 30px;
}

.careers-banner-overlay h1 {
    font-size: 6.5em;
    font-weight: 600;
    color: #ff4c4c;
    font-family: Copperplate;
    text-shadow: 2px 2px black;
    margin: 0 0 0 0;
}

.careers-banner-overlay h3 {
    font-size: 2.8em;
    font-weight: 400;
    color: whitesmoke;
    font-family: Copperplate; font-weight: 300;;
    text-shadow: 1px 1px black;
    border-bottom: 1px solid gainsboro;
    padding: 0 30px;
    margin: 0 0 50px 0;
}

.careers-banner-overlay p {
    font-size: 2.3em;
    color: white;
    font-family: Copperplate; font-weight: 300;;
}

.careers-banner:hover .careers-banner-overlay {
    display: flex;
}